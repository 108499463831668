.rbc-toolbar {
  border-top: 1px solid #E0E0E0;
  border-left: 1px solid #E0E0E0;
  border-right: 1px solid #E0E0E0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.rbc-day-bg.rbc-today {
  background-color: rgba(180, 198, 52, 0.2);
}

.rbc-day-bg.selected-slot {
  background-color: rgba(43, 78, 100, 0.2);
}

.rbc-month-view, .rbc-month-row:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.rbc-toolbar button.btn {
  padding: 6px 10px 9px 10px;
  font-size: 14px;
  color: #2B4E64;
  border-color: rgba(94, 100, 105, 0.25);
  border-radius: 6px;
}

.rbc-row.rbc-month-header .rbc-header {
  font-size: 1rem;
  font-weight: 600;
}

div.rbc-event {
  display: none;
}

@media (min-width: 992px) {
  .rbc-toolbar {
    border: none;
  }

  .rbc-toolbar button.btn {
    font-size: 18px;
    border-radius: 10px;
  }

  .rbc-month-view {
    border-radius: 16px;
  }

  .rbc-row.rbc-month-header .rbc-header {
    padding: 10px 0;
    color: rgba(94, 100, 105, 0.5);
  }

  .rbc-month-row:last-child {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  div.rbc-event {
    display: block;
    width: auto;
    height: auto;
    padding: 0 6px;
    margin-left: 0;
  }
}

.rbc-row .rbc-date-cell {
  text-align: left;
  padding-top: 5px;
  padding-left: 8px;
}

.rbc-day-bg.rbc-off-range-bg {
  background: #FFFFFF;
}

#event-popover {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1), 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
}

.popover-arrow::before {
  border: 1px solid;
}

.rbc-day-bg.rbc-off-range-bg {
  z-index: 0 !important;
}
