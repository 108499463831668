.bg-white-header {
  background-color: var(--bs-white) !important;
}

.nationwide-container {
  background: rgba(250, 250, 250, 0.98);
}

.map-welcome {
  width: 100%;
  margin-top: 3%;
  font-size: 1.85rem;
}

.explore-locations-title {
  width: 100%;
  color: var(--Dark-Blue-DarkBlue, #2B4E64);
  text-align: center;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}

.map-container {
  position: relative;
}

.map-button {
  position: absolute;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: white;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  transition: transform 0.3s ease;
  border: 1px solid black;
}

.map-button-outlet {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.map-button:hover {
  transform: scale(1.2);
}

.map-button-outlet:hover {
  transform: scale(1.2);
}

@media (max-width: 1200px) {
  .map-button {
    width: 30px;
    height: 30px;
  }

  .map-button-outlet {
    width: 30px;
    height: 30px;
  }
}