/* TODO: change this to whatever fonts are desired by SFC */
body {
  margin: 0;
  font-family: 'Nunito Sans', 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FCFCFD;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-box-shadow {
  box-shadow: -1px -1px 4px 0px rgba(0, 0, 0, 0.25) inset, 1px 1px 4px 1px rgba(255, 255, 255, 0.35) inset;
}

.ff-inter {
  font-family: 'Inter', 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fs-small {
  font-size: 0.875rem;
}

h6.heading-with-rule {
  color: #5E646940;
}

h6.heading-with-rule::after {
  display: block;
  content: "";
  border-top: 2px solid #5E646940;
  width: 87%;
  margin: -13px 117px;
  padding: 5px 0px;
}

.multi-content-scroll-short {
  max-height: 400px;
  max-width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.multi-content-scroll {
  max-height: 500px;
  max-width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.multi-content-scroll-tall {
  max-height: 700px;
  max-width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.edit-card-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.member-banner-text {
  position: relative;
  top: calc(-34px - 0.3vw);
  font-size: calc(0.5rem + 0.2vw);
}

.form-text {
  color: rgba(94, 100, 105, .5);
  font-size: .75rem;
  display: block;
  line-height: 1rem;
}

.rbt-input-multi.form-control.focus {
  border-color: #dae39a;
  color: #888888;
  box-shadow: 0 0 0 0.25rem rgba(180, 198, 52, 0.25);
}

.rbt-token.rbt-token-removeable {
  background-color: #B4C634;
  color: #FFFFFF;
}