.section-content {
    padding: 60px 20px 80px;
}

.subheader {
    color: #2b4e64;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-family: Nunito Sans, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
}

.subheader.tiny {
    font-size: 12px;
    line-height: 1.5em;
}

.recipe-child-sidebar {
    margin-top: 20px;
    padding-left: 20px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    overflow: hidden;
    height: 100%;
}

.image-recipe {
    margin-top: 20px;
    margin-bottom: 10px;
}

.section-content img {
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
}

.dots {
    height: 30px;
    background-image: url('../../../assets/images/dots.png');
    background-position: 0 0;
    background-repeat: repeat-x;
    background-size: auto;
}

.dots.recipe {
    height: 10px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.text-block-signature {
    color: #f05822;
    font-family: Gochi Hand, sans-serif;
    font-size: 28px;
    font-weight: 400;
    line-height: 1em;
}

.w-list-unstyled {
    padding-left: 0;
    list-style: none;
}

.section-content li {
    text-align: left;
    background-image: url('../../../assets/images/StickyFingers_weblogos-03.png');
    background-position: 0 2px;
    background-repeat: no-repeat;
    background-size: 20px;
    margin-bottom: 10px;
    padding-left: 30px;
}

.recipe-button {
    border-radius: 100px;
    padding: 10px 20px;
    border-color: #bcc74f;
    justify-content: center;
    align-items: center;
    display: flex;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    font-family: Nunito Sans, sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: #bcc74f;
}

.recipe-button:hover {
    background-color: transparent;
}

.recipe-button .button-icon {
    margin-right: 9px;
}

.image-recipe-fun-facts,
.image-recipe-fun-facts {
    margin-top: 10px;
    margin-bottom: 10px;
}

.fun-food-story h4,
.food-facts h4 {
    color: #2b4e64;
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: Nunito Sans, sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.2em;
}

.fun-food-story ul,
.food-facts ul {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 0;
    list-style-type: none;
}

.marquee {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
  }
  
.marquee p {
    display: inline-block;
    padding-left: 100%;
    animation: scroll 10s linear infinite;
}

@keyframes scroll {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}

.shop-button {
    border: 2px solid #bcc74f;
    border-radius: 50px;
    margin-top: 20px;
    padding: 9px 20px;
}