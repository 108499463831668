.indent {
    border-left: 3px solid #b91f69;
    padding-left: 40px;
}

.checklist {
    color: #2b4e64;
    /* margin-top: 20px;
    margin-bottom: 10px; */
    margin-right: 10px;
    font-size: 20px;
}

.flex-horizontal {
    justify-content: flex-start;
    align-items: center;
    display: flex;
}

.text-abc {
    color: #bcc74f;
    padding-right: 10px;
    font-family: Nunito Sans, sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.3em;
}

.text-abc.orange {
    color: #f05822;
}

.text-abc.blue {
    color: #3593c2;
}

.text-abc.tangerine {
    color: #ffa322;
}

.text-abc.purple {
    color: #b91f69;
}