@media (max-width: 1200px) {
  .flex-wrap-mobile {
    flex-wrap: wrap;
  }

  .text-center-mobile {
    text-align: center;
  }

  .course-card-link {
    text-align: center;
    display: flex;
    width: 100%;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .filters-container {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10%;
  }

  .map-container {
    margin-bottom: 5%;
  }
}