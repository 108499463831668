.recipe-card {
    transition: box-shadow .2s;
    overflow: hidden;
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, .1);
    border-radius: 0;
    border: 0;
}

.recipe-card:hover {
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, .25);
}

.recipe-card .card-img-top {
    border-radius: 0 !important;
}

.recipe-card .recipe-details {
    background-color: #f8faee;
    border-top: 2px solid #bcc74f;
    height: 292px;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
    display: flex;
    overflow: hidden;
}

.recipe-card .subheader {
    color: #2b4e64;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-family: Nunito Sans, sans-serif;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 6px;
    font-size: 13px;
}

.recipe-card .recipe-card-title a {
    color: #2b4e64;
    margin-bottom: 10px;
    font-family: Nunito Sans, sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.5em;
    text-decoration: none;
}

.recipe-card .recipe-card-title a:hover {
    text-decoration: underline;
}

.recipe-card .recipe-author {
    font-size: 14px;
}