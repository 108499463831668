.recipe-card .recipe-favorite,
.recipe-social .recipe-favorite {
    border-radius: 100px;
    margin-top: 30px;
    padding: 10px 20px;
    width: 50%;
    border-color: #bcc74f;
    justify-content: center;
    align-items: center;
    display: flex;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    font-family: Nunito Sans, sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: #bcc74f;
}

.recipe-social .recipe-favorite {
    width: auto !important;
    margin-top: 0 !important;
    margin-right: 20px;
}

.recipe-card .recipe-favorite:hover,
.recipe-social .recipe-favorite:hover {
    background-color: transparent;
}

.recipe-card .recipe-favorite .favorite-icon,
.recipe-social .recipe-favorite .favorite-icon {
    margin-right: 9px;
}