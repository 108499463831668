@import url('https://fonts.googleapis.com/css2?family=Gochi+Hand&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.bg-header{
  background-color: rgb(102, 173, 184);
  height: 120px;
}

.tab-container{
  height: 800px !important
}

.text-align-center{
  text-align: center;
}

.box-values{
  width: 200px;
  height: 200px;
  margin: 0% 1%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

}

.box-values-image{
  width: 100%;
  height: fit-content;
  padding: 0px 0px;
}


.box-image-container{
  width: 100%;
  height: 80%;
  align-content: center;
  justify-content: center;

}

.inspiration-img {
  width: 70% !important;
  padding-bottom: 1rem !important;
}

.bg-orange{
  background-color: var(--bs-orange);
  border:none;
}

.bg-orange:hover{
  background-color: var(--bs-orange);
  opacity: 0.5;
}

.pic-container{
  width:100px !important;
  height: 100px !important
}

.icons-contact{
  width: 25px !important;
  height: 25px !important;
  margin-left: 18px;
}

.margin-min{
  margin: 5px 0px !important;
}

.tabs-container {
  border: 0;
}

.nav-link {
  color: #888888 !important;
}

.nav-link.active {
  color: var(--bs-nav-link-color) !important;
  border: 0 !important;
  border-bottom: 1px solid var(--bs-nav-link-color) !important;
}

.link-icon {
  position: relative; 
  top: 6px; 
  left: -6px;
}

.div-news-item {
  border-radius: 10px;
  align-items: center;
  display: flex;
}

.div-news-details {
  margin-right: 20px;
}

.subheader.white {
  color: #fff;
}

.subheader.white.left {
  text-align: left;
}

.subheader.footer-menu {
  color: #f8faee;
  text-align: left;
}

.subheader.product-card {
  margin-bottom: 6px;
  font-size: 13px;
}

.subheader.blog-card {
  color: #333;
  text-align: left;
  margin-top: 15px;
  font-size: 13px;
}

.subheader.tiny {
  font-size: 12px;
  line-height: 1.5em;
}

.subheader.bold {
  font-weight: 700;
}

.subheader.bold.zero-margin-bottom {
  color: #333;
  margin-bottom: 0;
}

.subheader.large {
  font-size: 18px;
  line-height: 1.5em;
}

.subheader.center.testimonial {
  color: #b9206a;
  margin-bottom: 4px;
  font-weight: 700;
}

.subheader.tiny.zero-margin-bottom {
  color: #333;
  margin-bottom: 0;
}

.image-news {
  width: 200px;
}

.div-break {
  height: 2px;
  border-top: 1px dashed #d6d6d6;
  margin-top: 30px;
  margin-bottom: 30px;
}

.div-break._75 {
  width: 75%;
}

.div-break.breadcrumb {
  margin-top: 10px;
  margin-bottom: 10px;
}

.div-break.margin-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.div-break.solid {
  border-top: 2px solid rgba(188, 199, 79, .27);
}

.div-break.solid.fine {
  border-top-width: 1px;
}

.div-masonry-3-column {
  column-count: 3;
}

.div-masonry-2-column {
  column-count: 2;
  column-rule-style: none;
  column-rule-width: 0px;
  column-gap: 16px;
}

.div-masonry-content {
  box-shadow: none;
  text-align: center;
  background-color: #fff;
  border: 1px dashed #e0e0e0;
  border-radius: 10px;
  flex: 0 50%;
  align-self: flex-start;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 30px;
  display: inline-block;
}

.div-circle {
  width: 100px;
  height: 100px;
  background-color: #fff;
  border: 3px solid #e0e0e0;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.div-circle.grape {
  border-color: #b5c734;
  margin-bottom: 20px;
}

.image-testimonial {
  width: 70px;
  margin-left: auto;
  margin-right: auto;
}

.div-quotation {
  text-align: left;
}

.text-quotation-mark-testimonial {
  color: #c2c2c2;
  font-family: Rochester, sans-serif;
  font-size: 100px;
  line-height: 0;
}

.text-quotation-mark-testimonial.margin-top {
  color: #a1356c;
  margin-top: 60px;
  font-size: 200px;
}

.text-quotation {
  color: #333;
  margin-bottom: 20px;
}

.text-quotation.bold {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 600;
}

.small_link {
  color: #666;
  text-align: left;
  font-size: 14px;
}

.small_link.green {
  color: #b5be00;
}

.small_link._333 {
  color: #333;
}

.w-col {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
}
.w-col .w-col {
  padding-left: 0;
  padding-right: 0;
}

.w-list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-item {
  background-image: url('../../assets/images/SFC-li-img.png');
  background-position: 0 2px;
  background-repeat: no-repeat;
  background-size: 20px;
  margin-bottom: 10px;
  padding-left: 30px;
}

.child-50-rounded {
  background-color: #f8faee;
  border-radius: 10px;
  flex: 0 50%;
  margin-top: 10px;
  padding: 20px;
}

.child-50-rounded.margin-right {
  margin-right: 16px;
}

.image-bio {
  border-radius: 100px;
  margin-bottom: 20px;
  max-width:120px;
}

.image-bio.green {
  border: 5px solid #bcc74f;
}

.image-bio.plum {
  border: 5px solid #a1356c;
}

.image-bio.orange {
  border: 5px solid #f05822;
}

.text-bio {
  color: #666;
  margin-bottom: 20px;
  font-size: 14px;
}

.text-fa {
  font-family: "Fa 400";
  font-size: 18px;
}

.text-fa.padded {
  padding-right: 10px;
  font-family: "Font awesome 5 pro solid 900", sans-serif;
  font-size: 13px;
}

.text-fa.padded.standard {
  font-size: 16px;
}

.text-fa.padded.nav {
  padding-right: 8px;
  font-family: "Font awesome 5 pro solid 900", sans-serif;
}

.text-fa.pun {
  color: #f05822;
  padding-right: 5px;
  font-size: 14px;
}

.text-fa.checklist {
  color: #2b4e64;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-right: 10px;
  font-family: "Font awesome 5 pro solid 900", sans-serif;
  font-size: 20px;
}

.pun {
  color: #f05822;
  padding-right: 5px;
  font-size: 14px;
  font-weight: 600;
}

.fanchise-title-container{
  width: 100%;
  height: 100%;
}

.gradient-background {
  padding: 0;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.50) 5.74%, rgba(96, 152, 184, 0.50) 49.81%, rgba(43, 78, 100, 0.50) 94.27%);
}

.carousel-container-about-us {
  width: 100%;
}

.carousel-container-about-us .slick-dots {
  bottom: 10px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);;
  width: 25%;
  border-radius: 25px;
  right: 38%;
}

.carousel-container-about-us .slick-dots li {
  margin-bottom: 4px;
}

.contact-container {
  display: flex;
  flex-wrap: wrap;
}

.contact-text {
  font-family: 'Gochi Hand', sans-serif;
  color: #F05924;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.fruit-background {
  width: 100%;
  background-image: url('../../assets/images/testimonials-background.png');
}

.fruit-background.left{
  transform: scaleX(-1);
}

.testimonial-card {
  min-width: 100%;
}

.about-us-slider {
  width: 33.33%;
}

.core-values {
  flex-wrap: nowrap;
}

@media screen and (max-width: 991px) {
  .subheader.margin-top {
    margin-top: 20px;
  }

  .div-break._75 {
    width: auto;
  }

  .div-masonry-3-column {
    column-count: 2;
  }
}

@media screen and (max-width: 767px) {
  .div-news-item {
    align-items: flex-start;
  }

  .image-news {
    width: 140px;
  }
}

@media screen and (max-width: 479px) {
  .div-news-item {
    flex-direction: column;
  }

  .div-news-details {
    order: 1;
  }

  .subheader.white {
    height: auto;
  }

  .subheader.white.left, .subheader.footer-menu {
    text-align: center;
  }

  .subheader.slide {
    text-align: left;
  }

  .subheader.slide.right {
    text-align: right;
  }

  .subheader.product-card, .subheader.tiny.align-left {
    text-align: left;
  }

  .image-news {
    margin-bottom: 20px;
  }

  .div-masonry-3-column, .div-masonry-2-column {
    column-count: 1;
  }
}

@media (max-width: 1200px) {
  .fruit-background{
    width: 30%;
    background-size: cover;
  }

  .tabs-container {
    justify-content: center;

  }

  .nav-item {
    white-space: nowrap;
  }

  .welcome-text {
    text-align: center;
  }

  .about-us-slider {
    width: 100%;
  }

  .about-us-container {
    flex-wrap: wrap;
  }

  .core-values {
    flex-wrap: wrap;
  }

  .box-values {
    width: 150px;
    height: 150px;
    margin-bottom: 3%;
  }

  .recipe-image {
    width: 100%;
  }

  .available-jobs-content {
    text-align: center;
  }

  .child-50-rounded {
    flex: auto;
    width: 100%;
  }

  .child-50-rounded.margin-right {
    margin-right: 0px;
  }
}