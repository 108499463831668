.tippy-box[data-theme~='custom'] {
    background-color: #000000; /* Change to your desired color */
    color: #ffffff; /* Text color */
    border-radius: 4px; /* Optional: Add border radius */
    padding: 8px; /* Optional: Add padding */
  }
  
  .tippy-box[data-theme~='custom'][data-placement^='top'] > .tippy-arrow::before {
    border-top-color: #000000; /* Match arrow color with background */
  }
  