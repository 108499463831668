.states-locations .nav-item {
  border-bottom: 1px dashed #e0e0e0;
  width: 100%;
  font-size: 13px;
}

.states-locations .nav-item .nav-link {
  cursor: pointer;
}

.states-locations .nav-item.active {
  margin-bottom: 15px;
}

.states-locations .nav-item.active .submenu-content {
  padding-left: 10px;
  padding-right: 10px;
}

.states-locations .nav-item .arrow {
  font-size: 16px;
  color: var(--Dark-Blue-DarkBlue, #2B4E64);
}

.states-locations .nav-link {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 1% 0%;
}

.state-name {
  color: var(--Dark-Blue-DarkBlue, #2B4E64);
  font-family: "Nunito Sans";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}

.location-text {
  color: var(--Dark-Blue-DarkBlue-75, rgba(43, 78, 100, 0.75));
  font-family: "Nunito Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .location-text {
    font-size: 15px;
    margin-left: 5%;
    line-height: 8px;
  }

  .state-name {
    font-size: 22px;
  }
}