.section-banner {
    background-color: #f8faee;
    padding-top: 60px;
    padding-bottom: 60px;
}

.section-banner .blurb-image {
    margin-right: 20px;
}

.section-banner .blurb {
    width: 70%;
}

.section-banner h4 {
    color: #2b4e64;
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: Nunito Sans, sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.2em;
}

.section-banner p {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 1.5em;
}

@media screen and (min-width: 1440px) {
    .section-banner .blurb-image {
        margin-right: 30px;
    }
}

@media (max-width: 1200px) {
    .flex-wrap-mobile {
      flex-wrap: wrap;
    }

    .blurb-image {
      width: 100%;
      text-align: center;
    }

    .blurb {
      width: 100% !important;
      text-align: center;
    }
}