.section-banner-style-2 {
    /* background-image: linear-gradient(rgba(255, 255, 255, .3), rgba(255, 255, 255, .3)), url('../../assets/images/sticky-fingers-cooking-banner-6.jpg'); */
    background-position: 0 0, 50% 0;
    background-repeat: repeat, no-repeat;
    background-size: auto, cover;
    border-top: 1px solid #f8faee;
    border-bottom: 1px solid #f8faee;
    padding-top: 40px;
    padding-bottom: 40px;
}

.section-banner-style-2.green {
    background-color: #f8faee;
    background-image: none;
    background-position: 0 0;
    background-repeat: repeat;
    background-size: auto;
}

.section-banner-style-2.green.product {
    padding-top: 10px;
    padding-bottom: 10px;
}

.break {
    height: 2px;
    border-top: 1px dashed #d6d6d6;
    margin-top: 10px;
    margin-bottom: 10px;
}

.break.solid {
    border-top: 2px solid rgba(188, 199, 79, .27);
}

.break.solid.fine {
    border-top-width: 1px;
}

.section-banner-style-2 .breadcrumb {
    margin-bottom: 0 !important;
}

.section-banner-style-2 .breadcrumb-item, .breadcrumb-item a {
    text-transform: capitalize;
    color: #b9bf15;
    text-decoration: none;
    font-size: 13px;
}

.section-banner-style-2 .breadcrumb-item::before {
    color: #b9bf15;
}

.text-subtitle {
    font-size: 1.85rem;
}

@media (max-width: 1200px) {
    .text-subtitle {
        font-size: 1.35rem;
    }

    .text-center-mobile {
        text-align: center;
    }
}
  