@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

#sticky-notes .header-image-sticky-notes{
  text-align: center;
  background-image: linear-gradient(rgba(161, 53, 108, .95), rgba(161, 53, 108, .95)), url(../../assets/images/226294.png);
  background-position: 0 0, 0 0;
  background-size: auto, 400px;
  padding-top: 40px;
  padding-bottom: 40px;
  color:white;
}

#sticky-notes .blog-grid {
  grid-template-columns: 1fr 1fr 1fr;
}

#sticky-notes .div-blog-card {
  border: 1px dashed #e0e0e0;
  padding: 15px;
  overflow: hidden;
}

#sticky-notes .div-blog-grid {
  position: relative;
}

#sticky-notes .div-blog-card-image-wrapper {
  transition: opacity .3s;
  position: relative;
}

#sticky-notes .div-blog-card-image-wrapper:hover {
  opacity: .9;
}

#sticky-notes .div-blog-card-details {
  height: 200px;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  display: flex;
}

#sticky-notes .blog-card-title {
  color: #bcc74f;
  text-align: left;
  margin-bottom: 10px;
  font-family: Nunito Sans, sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.4em;
  transition: color .3s;
}

#sticky-notes .blog-card-title:hover {
  color: #2b4e64;
}

#sticky-notes .text-blog-byline {
  color: #fff;
  letter-spacing: .4px;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 700;
}

#sticky-notes .section-banner-blog-post {
  background-image: none;
  background-repeat: repeat;
  background-size: auto;
  border-top: 1px solid #f8faee;
  padding: 20px;
}

#sticky-notes .heading-blog-post {
  width: 67%;
  font-size: 42px;
}

#sticky-notes .section-blog-post {
  padding: 20px 20px 80px;
}

#sticky-notes .div-block-blog-post-image {
  border: 1px dashed #e0e0e0;
  margin-bottom: 40px;
  padding: 40px;
}

#sticky-notes .div-block-blog-copy {
  text-align: left;
}

#sticky-notes .w-inline-block {
  max-width: 100%;
  display: inline-block;
}

a {
  color: #bcc74f;
  text-decoration: none;
}

#sticky-notes .text-block-product {
  color: #333;
  text-align: left;
}
#sticky-notes .text-available {
  color: #a1356c;
  text-align: left;
  font-weight: 600;
}

#sticky-notes .w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;

  grid-auto-columns: 1fr;
  display: grid;
}
#sticky-notes .blog-grid {
  grid-template-columns: 1fr 1fr 1fr;
}