@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

#WhyWeAreGrape a {
  color: #bcc74f;
  text-decoration: none;
  
}


#WhyWeAreGrape .text-weight-700{
  font-weight: 700;
}

#WhyWeAreGrape .text-weight-600{
  font-weight: 600;
}

#WhyWeAreGrape .text-uppercase{
  text-transform: uppercase;
}



#WhyWeAreGrape .button-find-more{
  text-align: end;
  width: 100%;
}

#WhyWeAreGrape .bg-white{
  background-color: var(--bs-white);
}

#WhyWeAreGrape .bg-yellow{
  background-color: #f8faee;
}
#WhyWeAreGrape .card-option{
  width: 44%;
  margin: 3% ;
  height: auto;
  border-radius: 16px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

#WhyWeAreGrape .card-header{
  height: 300px;
  border-radius: 16px !important;
}

#WhyWeAreGrape .card-background{
  position: relative;
  background-image: url('../../assets/images/card-background.png');
  z-index: 10 !important;
  border-radius: 18px;
  background-size: 105% 105%;
  background-position: center; 
  background-repeat: no-repeat; 
  width: 100%;
  height: auto;
  margin: 0px !important; 
  padding: 0px !important;
}


#WhyWeAreGrape .card-description{

  border-radius: 16px !important;
  height: 330px;
  padding-top: -20px;
  margin-top: -20px;
  z-index: 12 ;
  position: relative;
  color: var(--bs-white);
  padding: 20px;
}

#WhyWeAreGrape .card-blue{
  background-color: #2B4E64BF;
}

#WhyWeAreGrape .card-red{
  background-color: #B81F69BF;
}

#WhyWeAreGrape .card-orange{
  background-color: #F05924BF;
}

#WhyWeAreGrape .card-green{
  background-color: #B4C634BF;
}

#WhyWeAreGrape .button-card{
  background-color: transparent;
  border: 1px solid #fff
}

#WhyWeAreGrape .button-card:hover{
  color: var(--bs-info) !important;
  background-color: #fff !important;
  opacity: 0.7;
  border: 1px solid #fff
}

#WhyWeAreGrape .button-card-align{
  display: flex;
  position: absolute;
  bottom: 0px;
  width: 93%;
  margin-bottom: 30px;
}

#WhyWeAreGrape .text-blue {
  color: var(--bs-info) !important;
}

#WhyWeAreGrape .text-center {
  text-align: center;
}

#WhyWeAreGrape .step-column{
  margin: 2% 1%; 
  width: 30%; 
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

#WhyWeAreGrape .step-image{

  min-width: 200px;
  min-height: 200px;
  max-width: 200px;
  max-height: 200px;
}

#WhyWeAreGrape .button-radius{
  border-radius: 36px;
  padding: 12px 24px !important;
}

#WhyWeAreGrape .letter-spacing-max{
  letter-spacing: 0.3rem;
}

#WhyWeAreGrape .letter-spacing-min{
  letter-spacing: 0.08rem;
}

#WhyWeAreGrape .video-container{
  width: 620px; 
  height: 400px
}

#WhyWeAreGrape .card-fruit{
  width: 120px;
  opacity:1
}

#WhyWeAreGrape .card-fruit-container{
  width: 180px;
  height: 200px;
  margin: 10px 10px;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

#WhyWeAreGrape .offset-min{
  margin-left: 3.333%;
}



#WhyWeAreGrape .footer {
  text-align: center;
  background-image: linear-gradient(rgba(43, 78, 100, .96), rgba(43, 78, 100, .96)), url('../../assets/images/footer-background.png');
  background-position: 0 0, 0 0;
  background-size: auto, 400px;
  align-items: flex-start;
  padding-top: 40px;
  padding-bottom: 40px;
}

#WhyWeAreGrape .white{
  color: #fff;
}

#WhyWeAreGrape .section-testimonial-slider {
  text-align: center;
  background-color: #2b4e64;
  background-image: url('../../assets/images/testimonials-background.png');
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: auto;
  padding: 100px 20px;
}

#WhyWeAreGrape .line-divider-footer {
  width: 100%;
  height: 1px;
  background-color: #e1e1e1;
  margin-top: 30px;
  margin-bottom: 30px;
  display: block;
}

#WhyWeAreGrape .line-divider-footer.second-style {
  height: 2px;
  background-color: #2b4e64;
  border-bottom: 1px dashed #6694b2;
  margin-bottom: 40px;
}

#WhyWeAreGrape .paragraph-copyright {
  color: #6694b2;
  font-size: 12px;
  line-height: 1.8em;
}

#WhyWeAreGrape .footer-flex-child-logo {
  text-align: left;
  flex: 0 30%;
}

#WhyWeAreGrape .footer-flex-child-menu {
  flex: 0 25%;
}

#WhyWeAreGrape .subheader {
  color: #2b4e64;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-family: Nunito Sans, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

#WhyWeAreGrape .subheader.white {
  color: #fff;
}

#WhyWeAreGrape .subheader.white.left {
  text-align: left;
}

#WhyWeAreGrape .subheader.footer-menu {
  color: #f8faee;
  text-align: left;
}

#WhyWeAreGrape .footer-navigation {
  color: #6694b2;
  text-align: left;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.2em;
  text-decoration: none;
  transition: color .1s;
  display: block;
}

#WhyWeAreGrape .footer-navigation:hover {
  color: #a6bbc9;
}

#WhyWeAreGrape .text-footer-contact {
  color: #6694b2;
  text-align: left;
  font-size: 12px;
  line-height: 1.5em;
}

#WhyWeAreGrape .div-footer-contact {
  align-items: center;
  padding-bottom: 15px;
  display: flex;
}

#WhyWeAreGrape .div-footer-contact.address {
  justify-content: flex-start;
}

#WhyWeAreGrape .text-quote {
  color: #fff;
  padding-bottom: 10px;
  font-family: Nunito Sans, sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 1.5em;
}

/* CAROUSEL TEST */
#WhyWeAreGrape .carousel-container {
  width: 50%;
}

#WhyWeAreGrape .carousel-item {
  text-align: left;
  width: 98%;
}

#WhyWeAreGrape .carousel-item img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

#WhyWeAreGrape .carousel-item h3 {
  margin: 10px 0;
}

#WhyWeAreGrape .carousel-item p {
  color: #666;
}

#WhyWeAreGrape .slick-dots li button::before {
  font-size: 16px !important;
  color: #fff !important;
}

#WhyWeAreGrape .slick-dots {
  text-align: left;
}

#WhyWeAreGrape .button-header{
  background-color: #A24672;
  border: none;
  width: auto;
  padding: 12px 50px;
  border-radius: 28px;
}

#WhyWeAreGrape .button-input{
  top: 0;
  right: 0;
  padding: 9px 20px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

  .button-contact{
    border: 2px solid #B4C634; 
    color: #B4C634 !important;
    background-color: transparent !important;
  }

  .button-contact:hover{
    border: 2px solid #ffffff; 
    color: #ffffff !important;
    background-color: transparent !important;
  }

  .section-fruit-container{
    display: flex;
    justify-content: center;
  }

  .section-fruit{
    width: 120px;
    position: absolute;
    margin-top: -40px;
  }

  .link-header{
    cursor: pointer;
    text-decoration: none !important; 
  }

  .link-header:hover{
  opacity: 0.5; 
  }




  .bullet-circle {
    content: '';
    display: inline-block;
    width: 8px; /* Adjust size as needed */
    height: 8px;
    background-color: #2B4E64; /* Match bullet color with text color */
    border-radius: 50%;
    margin-right: 8px; /* Adjust spacing between bullet and text */
    position: absolute;
    top: 0.5em; /* Adjust to align with text */
    left: 0;
  }

  .pl-bullet{
    padding-left: 1vw; /* Adjust space as needed */
  }

  .div-image {
    background-image: url(../../assets/images/Sticky-Fingers-Cooking_FruitToTile1_Cornucopia.png);
    background-position: top center;
background-repeat: no-repeat;
background-size: contain;
margin-top: 0;
}

#WhyWeAreGrape .div-image-2 {
  background-image: url(../../assets/images/Sticky-Fingers-Cooking_FruitToTile2_Cornucopia.png);
  background-position: top center;
background-repeat: no-repeat;
background-size: contain;
margin-top: 0;
}


#WhyWeAreGrape .image-events {
  display: block;          /* Ensures the image can be centered */
  margin: 0 auto !important; /* Centers the image horizontally */
  max-width: 75% !important;
  border-radius: 10px !important;
  transform: rotate(6deg) !important;
  background-position: top center !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
}

#WhyWeAreGrape .w-list-unstyled {
  padding-left: 0;
  list-style: none;
}

ul {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 0;
  list-style-type: none;
}

#WhyWeAreGrape .list-item {
  background-image: url(../../assets/images/StickyFingers_weblogos-03.png);
  background-position: 0 2px;
  background-repeat: no-repeat;
  background-size: 20px;
  margin-bottom: 10px;
  padding-left: 30px;
}
.w-list-unstyled li {
  text-align: left;
  background-image: url(../../assets/images/StickyFingers_weblogos-03.png);
  background-position: 0 2px;
  background-repeat: no-repeat;
  background-size: 20px;
  margin-bottom: 10px;
  padding-left: 30px;
}

#WhyWeAreGrape .fontSize9rem{
  font-size: 0.9rem;

}

#WhyWeAreGrape .div-break {
  height: 2px;
  border-top: 1px dashed #d6d6d6;
  margin-top: 30px;
  margin-bottom: 30px;
}
#WhyWeAreGrape .div-testimonial-card {
  width: 100%;
  height: 420px;
  overflow-y: auto;
  background-color: #f8faee;
  border-radius: 10px;
  flex: 0 33.3%;
  margin: 10px;
  padding: 30px;
}

#WhyWeAreGrape .carousel-wrapper .carousel-control-prev-icon,
#WhyWeAreGrape .carousel-wrapper .carousel-control-next-icon {
  filter: invert(36%) sepia(78%) saturate(4643%) hue-rotate(309deg) brightness(91%) contrast(100%);
  transform: scale(1.2); /* Increases the size of the arrow icons */
}

#WhyWeAreGrape .carousel-inner {
  position: relative;
  width: 80%; /* Set width to 80% */
  margin: 0 auto; /* Center the carousel-inner */
  overflow: hidden; /* Keep this if you want to maintain the existing overflow behavior */
}

#WhyWeAreGrape .div-circle.grape {
  border-color: #b5c734;
  margin-bottom: 20px;
}

#WhyWeAreGrape .div-circle {
  width: 100px;
  height: 100px;
  background-color: #fff;
  border: 3px solid #e0e0e0;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}


#WhyWeAreGrape .text-stars {
  color: #fcd53f;
  text-align: center;
  letter-spacing: 4px;
  margin-bottom: 10px;
  font-family: "Font awesome 5 pro solid 900", sans-serif;
  font-size: 14px;
}


#WhyWeAreGrape .text-testimonial-card {
  text-align: center;
  margin-bottom: 20px;
}

#WhyWeAreGrape .subheader.center.testimonial {
  color: #b9206a;
  margin-bottom: 4px;
  font-weight: 700;
}

#WhyWeAreGrape .text-testimonial-venue {
  text-align: center;
  font-size: 14px;
}

#WhyWeAreGrape .white {
  color: #333;
  background-color: #fff;
}

#WhyWeAreGrape .card-grids-container {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  text-align: left;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  padding-top: 20px;
  padding-bottom: 60px;
  display: grid;
}

#WhyWeAreGrape .card-grids-container.dual {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
}

#WhyWeAreGrape .w-inline-block {
  max-width: 100%;
  display: inline-block;
}

#WhyWeAreGrape .div-event {
  height: 360px;
  background-image: radial-gradient(circle at 100% 100%, rgba(255, 255, 255, 0) 6%, rgba(255, 255, 255, .98) 89%), url(../../assets/images/sticky-fingers-cooking-birthday-parties-for-kids_003.jpg);
  background-position: 0 0, 0 0;
  background-repeat: repeat, no-repeat;
  background-size: auto, cover;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  transition: box-shadow .3s, transform .3s;
  box-shadow: 4px 4px 25px 1px rgba(0, 0, 0, .15);
}

#WhyWeAreGrape .div-70 {
  width: 70%;
}

#WhyWeAreGrape .subheader {
  color: #2b4e64;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-family: Nunito Sans, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

#WhyWeAreGrape .h2-events {
  height: 200px;
  margin-top: 10px;
  overflow: hidden;
  color: #2b4e64;
    margin-bottom: 20px;
    font-family: Nunito Sans, sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 1.2em;
}

#WhyWeAreGrape .zero-margin-top.green {
  margin-top: 0;

  background-color: #bcc74f;
  border-color: #bcc74f;
}



#WhyWeAreGrape .div-faux-button {
  border: 2px solid #fff;
  border-radius: 100px;
  margin-top: 30px;
  padding: 10px 20px;
  display: inline-block;
}

#WhyWeAreGrape .faux-button {
  color: #fff;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-family: Nunito Sans, sans-serif;
  font-size: 14px;
  font-weight: 700;
}

#WhyWeAreGrape .link-block-card:hover {
  text-decoration: none;
}

#WhyWeAreGrape .div-event {
  height: 360px;
  background-image: radial-gradient(circle at 100% 100%, rgba(255, 255, 255, 0) 6%, rgba(255, 255, 255, .98) 89%), url(../../assets/images/sticky-fingers-cooking-birthday-parties-for-kids_003.jpg);
  background-position: 0 0, 0 0;
  background-repeat: repeat, no-repeat;
  background-size: auto, cover;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  transition: box-shadow .3s, transform .3s;
  box-shadow: 4px 4px 25px 1px rgba(0, 0, 0, .15);
}

#WhyWeAreGrape .div-event:hover {
  transform: translate(0, -6px);
  box-shadow: 4px 4px 25px 1px rgba(0, 0, 0, .25);
}

#WhyWeAreGrape .div-event.family {
  background-image: radial-gradient(circle at 100% 100%, rgba(255, 255, 255, 0) 4%, rgba(255, 255, 255, .98) 89%), url('../../assets/images/private-events-family-cooking-classes_Sticky-Fingers-Cooking.jpg');
  background-position: 0 0, 0 0;
  background-repeat: repeat, no-repeat;
  background-size: auto, cover;
}

#WhyWeAreGrape .div-event.scouts {
  background-image: radial-gradient(circle at 100% 100%, rgba(255, 255, 255, 0) 6%, rgba(255, 255, 255, .98) 89%), url('../../assets/images/istockphoto-517677809-612x612.jpg');
  background-position: 0 0, 0 0;
  background-repeat: repeat, no-repeat;
  background-size: auto, auto;
}

#WhyWeAreGrape .div-event.corporate {
  background-image: radial-gradient(circle at 100% 100%, rgba(255, 255, 255, 0) 7%, rgba(255, 255, 255, .98) 89%), url('../../assets/images/corporate-team-building-cooking-events_Sticky-Fingers-Cooking.jpg');
  background-position: 0 0, 0 0;
  background-repeat: repeat, no-repeat;
  background-size: auto, cover;
}

#WhyWeAreGrape .container-cards {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

#WhyWeAreGrape .section-banner-style-events {
  background-image: url(../../assets/images/banner.png);
  background-position: top center;
background-repeat: no-repeat;
background-size: cover;
padding: 40px 20px;

}

#WhyWeAreGrape .w--current {
  color: #b9bf15;
  border-bottom: 1px solid #bcc74f;
}
#WhyWeAreGrape .div-fruit {
  
  background-color: #fff;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

#WhyWeAreGrape .fruit-text{
  cursor: default;
}

#WhyWeAreGrape .hidden{
  display: none;
}

#WhyWeAreGrape .visible{
  display: block;
}
 .whyWeAreGrape .nav-item{
  background-image: none;
}
