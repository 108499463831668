.recipe-pagination {
    max-width: 800px;
    overflow-x: scroll;
    justify-content: left;
    align-items: center;
    margin-top: 60px;
    display: flex
}

.recipe-pagination .link-pagination {
    padding: 10px 15px;
    max-width: 100%;
    display: inline-block;
    font-size: 18px;
    text-decoration: none;
    cursor: pointer;
}

.recipe-pagination .link-pagination:hover,
.recipe-pagination .link-pagination:active {
    opacity: .4;
}