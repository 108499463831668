.custom-offcanvas.offcanvas {
  width: 80%;
  max-width: 350px;
}

.sidebar {
  position: fixed;
  left: -350px;
  width: 350px;
  transition: left 0.3s ease-in-out;
  background-color: #FFFFFF;
  border-right: 1px solid rgba(136, 136, 136, 0.25);
  z-index: 99;
}

.sidebar-toggle {
  position: absolute;
  right: -28px;
  z-index: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.sidebar.open {
  left: 0;
}

@media (min-width: 992px) {
  .sidebar.open ~ .content {
    max-width: calc(100vw - 350px);
    margin-left: 350px; /* Adjust based on sidebar width and collapse button */
  }
}

.dash-navbar-body .dropdown-toggle::after {
  display: none;
}

.dash-navbar-body .navbar-nav .nav-link {
  color: #888888;
  padding: 10px 0;
  border-radius: 4px;
}

.dash-navbar-body .navbar-nav .nav-link:hover {
  color: #B4C634;
}

.dash-navbar-body .navbar-nav .nav-link.active {
  color: #FFFFFF;
}

.dash-navbar-body .nav-item.show.dropdown #nav-dropdown, .dash-navbar-body .nav-link.active {
  color: #FFFFFF !important;
  box-shadow: -1px -1px 4px 0px rgba(0, 0, 0, 0.25) inset, 1px 1px 4px 1px rgba(255, 255, 255, 0.35) inset;
}

.dropdown-menu {
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2), -2px 2px 4px 0px rgba(0, 0, 0, 0.2);
  padding: 0;
}

.dash-navbar-body .dropdown-menu {
  box-shadow: none;
}

.dash-navbar-body .dropdown-menu .dropdown-item {
  color: #888888;
}

.dash-navbar-body .dropdown-menu .dropdown-item:hover,
.dash-navbar-body .dropdown-menu .dropdown-item.active {
  color: #B4C634;
  background-color: #FFFFFF;
}
