.favorite-button {
    width: 300px;
    text-decoration: none;
    font-weight: 700;
    color: #5E6469BF;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.3rem;
}

.favorite-button-no-label {
    text-decoration: none;
    font-weight: 700;
    color: #5E6469BF;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.3rem;
}

.favorite-button-icon {
    color: #5E646980
}

.favorite-button:hover .favorite-button-icon,
.favorite-button-active .favorite-button-icon {
    color: #B81F69;
}

.favorite-button-no-label {
    font-size: 24px;
    margin-right: 22px;
}