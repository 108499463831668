.recipes-filters .nav-item {
    border-top: 1px dashed #e0e0e0;
    width: 100%;
    font-size: 13px;
}

.recipes-filters .nav-item .nav-link {
    cursor: pointer;
}

.recipes-filters .nav-item.active {
    margin-bottom: 15px;
}

.recipes-filters .nav-item.active .submenu-content {
    padding-left: 10px;
    padding-right: 10px;
}

.recipes-filters .nav-item .arrow {
    float: right;
    font-size: 14px;
    color: #bcc74f;
}

.slider-thumb {
    position: absolute;
    top: -39px;
    z-index: 3;
    background-color: #bcc74f;
    color: #fff;
    width: 28px;
    height: 28px;
    border-radius: 28px;
    -webkit-border-radius: 28px;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    text-align: center;
}

.slider-thumb #value {
    font-size: 12px;
    font-weight: 700;
    line-height: 28px;
}

.slider-thumb::after {
    position: absolute;
    content: '';
    left: 0;
    border-radius: 16px;
    top: 19px;
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    border-top-width: 16px;
    border-top-style: solid;
    border-top-color: #bcc74f;
}

.recipes-filters .form-check-label {
    color: #000;
}