.filter-navbar {
  position: fixed;
  right: -380px;
  height: 100%;
  width: 380px;
  transition: right 0.3s ease-in-out;
  background-color: #FFFFFF;
  border-right: 1px solid rgba(136, 136, 136, 0.25);
  z-index: 99;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, .1), 0 0 8px 0 rgba(0, 0, 0, .1);

}

.filter-navbar-toggle {
  position: absolute;
  right: 380px;
  z-index: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.filter-navbar.open {
  right: 0;
}

@media (min-width: 992px) {
  .filter-navbar.open ~ .content {
    max-width: calc(100vw - 320px);
    margin-right: 350px; /* Adjust based on sidebar width and collapse button */
  }
}

@media (min-width: 992px) {
  .filter-navbar.cover-sidebars ~ .content {
    max-width: calc(100vw);
    margin-right: 30px; /* Adjust based on sidebar, sidebars width and collapse button */
  }
}
