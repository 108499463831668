.course-img {
    border-radius: 16px;
    height: 406px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.course-detail-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;
}

.course-detail-row .detail-icon {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.course-detail-row .detail-text {
    padding: 0 3px;
    flex: 1;
    align-self: flex-start;
}

.accordion-sub {
    color: #5E646980;
}

.confirmation-modal,
.register-modal {
    width: 640px;
    max-width: 640px;
}