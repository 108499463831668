.children-select .form-check-label {
    font-weight: 600;
    color: #5E6469;
}

.children-select .add-child {
    cursor: pointer;
    font-weight: 700;
    color: #F05924;
}

.course-information .prorated,
.course-information .classes-total {
    color: #A2B22F;
}

.course-information .classes-total {
    padding-left: 5px;
}

.classes-remaining,
.students-information {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
}

.classes-remaining .icon,
.students-information .icon {
    margin-right: 3px;
}