.cart-icon-container {
  position: relative;
  display: inline-block;
  margin: 1rem;
}

.cart-icon-badge {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  background: #B81F69 !important;
  color: white;
  border-radius: 50%;
  padding: 5px 8px;
  font-size: 0.75rem;
}