.recipe-features {
    background-color: #f8faee;
    margin-top: 20px;
}

.recipe-features {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.recipe-features-copy {
    padding-top: 10px;
    padding-bottom: 10px;
}

.column-recipe-features {
    border-right: 1px dashed #d6d6d6;
}