.table-layout .nav-link {
  color: rgba(136, 136, 136, 0.5);
  text-transform: uppercase;
  font-weight: 600;
}

.table-layout .nav-link:hover {
  color: rgba(136, 136, 136, 1);
}

.table-layout .nav-link.active {
  color: #334E62;
}

@media screen and (max-width: 991px) {
  .mobile-grid {
      display: block;
  }
}

@media only screen and (min-width: 1024px) {
  .mobile-grid {
    display:none;
  }
}

@media screen and (max-width: 479px) {
  .community-grid {
      grid-template-columns: 1fr;
      display: block;
      grid-row-gap: 16px;
    grid-column-gap: 16px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    display: grid;
  }
}

.div-rounded {
  height: 30vh;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  display: flex;
  position: relative;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, .15);
  margin:10px

}