.instruction-steps {
    align-items: flex-start;
    padding: 20px;
    display: flex;
}

.instruction-steps:hover {
    background-color: #f8faee;
}

.text-block-steps {
    color: #bcc74f;
    margin-right: 20px;
    font-family: Nunito Sans, sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 32px;
}

.step-title {
    margin-top: 0;
    font-size: 24px;
    font-weight: 300;
}