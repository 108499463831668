.recipe-social {
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
}

.recipe-social-link {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 24px;
    display: inline !important;
    color: #bcc74f;
    text-decoration: none;
}