@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.header-text {
  color: var(--bs-info);
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  font-weight: 700 !important;
  align-items: center;
}

.text-weight-700 {
  font-weight: 700;
}

.text-weight-600 {
  font-weight: 600;
}

.text-uppercase {
  text-transform: uppercase;
}

.header-search {
  width: 30%;
  height: auto;
  background: linear-gradient(to bottom, #fff, #6098b8, #2b4e64);
  font-weight: 700;
  padding: 20px;
  color: var(--bs-white);
}

.header-search-fruits {
  width: 75%;
}

.header-search-link {
  color: var(--bs-white);
  text-transform: uppercase;
  font-size: 0.9rem;
  display: flex;
  justify-content: center;
}

.button-find-more {
  text-align: end;
  width: 100%;
}

.bg-white {
  background-color: var(--bs-white);
}

.card-option {
  width: 44%;
  margin: 3%;
  height: auto;
  border-radius: 16px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.card-header {
  height: 300px;
  border-radius: 16px !important;
}

.card-background {
  position: relative;
  background-image: url("../../assets/images/card-background.png");
  z-index: 10 !important;
  border-radius: 18px;
  background-size: 105% 105%;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  margin: 0px !important;
  padding: 0px !important;
}

.card-description {
  border-radius: 16px !important;
  height: 330px;
  padding-top: -20px;
  margin-top: -20px;
  z-index: 12;
  position: relative;
  color: var(--bs-white);
  padding: 20px;
}

.card-blue {
  background-color: #2b4e64bf;
}

.card-red {
  background-color: #b81f69bf;
}

.card-orange {
  background-color: #f05924bf;
}

.card-green {
  background-color: #b4c634bf;
}

.button-card {
  background-color: transparent;
  border: 1px solid #fff;
}

.button-card:hover {
  color: var(--bs-info) !important;
  background-color: #fff !important;
  opacity: 0.7;
  border: 1px solid #fff;
}

.button-card-align {
  display: flex;
  position: absolute;
  bottom: 0px;
  width: 93%;
  margin-bottom: 30px;
}

.text-blue {
  color: var(--bs-info) !important;
}

.step-column {
  margin: 2% 1%;
  width: 30%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.step-image {
  min-width: 200px;
  min-height: 200px;
  max-width: 200px;
  max-height: 200px;
}

.button-radius {
  border-radius: 36px;
  padding: 12px 24px !important;
}

.letter-spacing-max {
  letter-spacing: 0.3rem;
}

.letter-spacing-min {
  letter-spacing: 0.08rem;
}

.video-container {
  width: 620px;
  height: 400px;
}

.card-fruit {
  width: 120px;
  opacity: 1;
}

.card-fruit-container {
  width: 180px;
  height: 200px;
  margin: 10px 10px;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.offset-min {
  margin-left: 3.333%;
}

.footer {
  text-align: center;
  background-image: linear-gradient(
      rgba(43, 78, 100, 0.96),
      rgba(43, 78, 100, 0.96)
    ),
    url("../../assets/images/footer-background.png");
  background-position: 0 0, 0 0;
  background-size: auto, 400px;
  align-items: flex-start;
  padding-top: 40px;
  padding-bottom: 40px;
}

.text-white {
  color: #fff;
}

.section-testimonial-slider {
  text-align: center;
  background-color: #2b4e64;
  background-image: url("../../assets/images/testimonials-background.png");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: auto;
  padding: 100px 20px;
}

.line-divider-footer {
  width: 100%;
  height: 1px;
  background-color: #e1e1e1;
  margin-top: 30px;
  margin-bottom: 30px;
  display: block;
}

.line-divider-footer.second-style {
  height: 2px;
  background-color: #2b4e64;
  border-bottom: 1px dashed #6694b2;
  margin-bottom: 40px;
}

.paragraph-copyright {
  color: #6694b2;
  font-size: 12px;
  line-height: 1.8em;
}

.footer-flex-child-logo {
  text-align: left;
  flex: 0 30%;
}

.footer-flex-child-menu {
  flex: 0 25%;
}

.subheader {
  color: #2b4e64;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-family: Nunito Sans, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.subheader.white {
  color: #fff;
}

.subheader.white.left {
  text-align: left;
}

.subheader.footer-menu {
  color: #f8faee;
  text-align: left;
}

.footer-navigation {
  color: #6694b2;
  text-align: left;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.2em;
  text-decoration: none;
  transition: color 0.1s;
  display: block;
}

.footer-navigation:hover {
  color: #a6bbc9;
}

.text-footer-contact {
  color: #6694b2;
  text-align: left;
  font-size: 12px;
  line-height: 1.5em;
}

.div-footer-contact {
  align-items: center;
  padding-bottom: 15px;
  display: flex;
}

.div-footer-contact.address {
  justify-content: flex-start;
}

.text-quote {
  color: #fff;
  padding-bottom: 10px;
  font-family: Nunito Sans, sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 1.5em;
}

/* CAROUSEL TEST */
.carousel-container {
  width: 50%;
}

.carousel-size{
  height: 600px !important;
  padding: 40px;
  display: flex;
  flex-wrap: wrap;
}

.item-testimonial {
  text-align: left;
}

.carousel-item img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.carousel-item h3 {
  margin: 10px 0;
}

.carousel-item p {
  color: #666;
}

.slick-dots li button::before {
  font-size: 16px !important;
  color: #fff !important;
}

.slick-dots {
  text-align: left;
}

.button-header {
  background-color: #a24672;
  border: none;
  width: auto;
  padding: 12px 50px;
  border-radius: 28px;
}

.button-input {
  top: 0;
  right: 0;
  padding: 9px 20px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.button-contact {
  border: 2px solid #b4c634;
  color: #b4c634 !important;
  background-color: transparent !important;
}

.button-contact:hover {
  border: 2px solid #ffffff;
  color: #ffffff !important;
  background-color: transparent !important;
}

.section-fruit-container {
  display: flex;
  justify-content: center;
}

.section-fruit {
  width: 96px;
  position: absolute;
  margin-top: -40px;
}

.link-header {
  cursor: pointer;
  text-decoration: none !important;
}

.link-header:hover {
  opacity: 0.5;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin-animation {
  animation: spin 0.5s linear;
}

.text-data {
  font-size: 14px;
}

.card-fruit-text {
  display: none;
  text-align: center;
  padding: 10px;
}

.card-fruit-container:hover .card-fruit-text {
  display: block;
}

.card-fruit-container:hover .card-fruit {
  display: none;
}

.images-slider-container {
  width: 70%;
}

.header-image {
  width: 100%;
  height: auto;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-content: center;
  align-items: center;
}

.header-image-0 {
  background-image: radial-gradient(circle at 100% 100%, rgba(255, 255, 255, 0) 27%, rgba(255, 255, 255, .8) 85%), url("../../assets/images/header-image-0.jpg");
}

.header-image-1 {
  background-image: radial-gradient(circle at 100% 0, rgba(255, 255, 255, .74) 31%, rgba(255, 255, 255, 0) 64%), url("../../assets/images/header-image-1.jpg");
}

.header-image-2 {
  background-image: radial-gradient(circle at 100% 0, rgba(255, 255, 255, .74) 31%, rgba(255, 255, 255, 0) 64%), url("../../assets/images/header-image-2.jpg");
}

.header-image-3 {
  background-image: radial-gradient(circle at 100% 0, rgba(255, 255, 255, .74) 31%, rgba(255, 255, 255, 0) 64%), url("../../assets/images/header-image-3.jpg");
}

.img-title {
  width: 50%;
  color: #2b4e64;
  padding-bottom: 10px;
  font-family: Nunito Sans, sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 1.5em;
}

.img-subtitle {
  color: #2b4e64;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-family: Nunito Sans, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.images-slider-container .slick-dots {
  bottom: 10px;
  text-align: center;
}

.as-seen-on-imgs {
  width: 13%;
}

.community-text {
  width: 35%;
}

.community-imgs {
  width: 65%;
}

.community-content {
  flex-wrap: nowrap;
}

.serving-grades-text {
  width: 33.33%;
}

.serving-grades-video {
  width: 50%;
}

@media (max-width: 1200px) {
  .images-slider-container {
    width: 100%;
  }

  .carousel-size{
    height: 400px !important;
    padding: 40px;
  }

  .img-title {
    width: 100%;
  }

  .header-image-0 {
    background-image: 
    linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), 
    url("../../assets/images/header-image-0.jpg");
  }
  
  .header-image-1 {
    background-image: 
    linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), 
    url("../../assets/images/header-image-1.jpg");
  }
  
  .header-image-2 {
    background-image: 
    linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), 
    url("../../assets/images/header-image-2.jpg");
  }
  
  .header-image-3 {
    background-image: 
    linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), 
    url("../../assets/images/header-image-3.jpg");
  }

  .header-search {
    width: 100%;
    text-align: center;
  }

  .header-search-fruits {
    width: 50%;
  }

  .button-find-more {
    padding-bottom: 10%;
  }

  .card-option {
    width: 100%;
  }

  .card-header {
    height: auto;
  }

  .card-description {
    height: auto;
  }

  .button-card-align {
    position: relative;
    width: 100%;
  }

  .step-column {
    width: 100%;
  }

  .as-seen-on-imgs {
    width: 32%;
  }

  .community-text {
    width: 100%;
    text-align: center;
  }
  
  .community-imgs {
    width: 100%;
  }

  .community-content {
    flex-wrap: wrap;
  }

  .card-fruit-container {
    width: 42%;
  }

  .section-testimonial-slider{
    background-position: -80%;
  }

  .carousel-container {
    width: 100%;
  }

  .text-quote {
    font-size: 32px;
  }

  .steps-title {
    text-align: center;
  }

  .serving-grades-text {
    width: 100%;
    text-align: center;
  }

  .serving-grades-video {
    width: 100%;
  }

  .video-container {
    width: 100%;
    height: 300px;
  }

  .social-links {
    width: 100% !important;
    padding: 0% 10% 10% 10%;
  }

  .button-contact {
    width: 100% !important;
    margin: 0% 10% 0% 10%;
  }

  .footer-flex-child-logo {
    text-align: center;
    flex: auto;
  }

  .footer-flex-child-menu {
    text-align: center;
    flex: auto;
    width: 100%;
  }

  .footer-flex-child-menu {
    text-align: center;
    flex: auto;
    width: 100%;
    margin-bottom: 5%;
  }

  .our-programming-content {
    width: 100%;
    text-align: center;
  }

  .footer-navigation {
    text-align: center;
  }

  .footer-flex-child-contact {
    text-align: center;
    flex: auto;
    width: 100%;
  }

  .div-footer-contact {
    text-align: center;
    justify-content: center !important;
  }
}