.react-calendar {
    background-color: #FAFAFA !important;
    border: 1px solid #E0E0E0 !important;
    border-radius: 8px;
    width: 100% !important;
    font-family: 'Nunito Sans', 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
}

.react-calendar__navigation {
    margin-left: 1.4rem;
    margin-right: 1.4rem;
    margin-top: 1rem;
}

.react-calendar__navigation__arrow {
    font-size: 1.5rem;
    border: 1px solid #E0E0E0 !important;
    border-radius: 10px;
    height: 34px !important;
    width: 32.11px !important;
    min-width: auto !important;
}

.react-calendar__navigation__label__labelText {
    display: inline;
    white-space: pre-wrap;
}

.react-calendar__navigation__label__labelText .month {
    color:#2B4E64;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 24px;
}

.react-calendar__navigation__label__labelText .year {
    color:#5E6469BF;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 16px;
}

.react-calendar__navigation__label {
    margin-top: 10px;
}

.react-calendar__month-view__weekdays__weekday {
    text-transform: capitalize;
    color:#5E6469BF;
    font-size: 16px;
    font-weight: 600;
}

.react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none !important;
}

.react-calendar__month-view__days__day {
    font-size: 24px;
    color: #333333;
}

.react-calendar__month-view__days__day--neighboringMonth {
    color: #5E646980;
}

.react-calendar__tile {
    padding-top: 13px;
    padding-bottom: 13px;
}

.react-calendar__tile--active {
    background: none !important;
}

.class-date {
    color: #A2B22F;
}

.no-class-date {
    color: #B81F69;
}

.class-date-selected {
    color: #fff;
    background: #B4C634 !important;
    border-radius: 4px;
}