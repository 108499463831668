.course-card.card.pink {
  border-left: 10px solid var(--bs-sfc-pink);
}

.course-card.card.orange {
  border-left: 10px solid var(--bs-sfc-orange);
}

.course-card.card.green {
  border-left: 10px solid var(--bs-sfc-green);
}

.course-card.card.blue {
  border-left: 10px solid var(--bs-sfc-blue);
}

.course-card.card.blueberry {
  border-left: 10px solid var(--bs-sfc-blueberry);
}

.course-card-simple.card.pink {
  border-top: 10px solid var(--bs-sfc-pink);
}

.course-card-simple.card.orange {
  border-top: 10px solid var(--bs-sfc-orange);
}

.course-card-simple.card.green {
  border-top: 10px solid var(--bs-sfc-green);
}

.course-card-simple.card.blue {
  border-top: 10px solid var(--bs-sfc-blue);
}

.course-card-simple.card.blueberry {
  border-top: 10px solid var(--bs-sfc-blueberry);
}

.selected-course-card.pink {
  border-radius: 10px;
  border: 5px solid var(--bs-sfc-pink);
  border-left: 10px solid var(--bs-sfc-pink);
}

.selected-course-card.orange {
  border-radius: 10px;
  border: 5px solid var(--bs-sfc-orange);
  border-left: 10px solid var(--bs-sfc-orange);
}

.selected-course-card.green {
  border-radius: 10px;
  border: 5px solid var(--bs-sfc-green);
  border-left: 10px solid var(--bs-sfc-green);
}

.selected-course-card.blue {
  border-radius: 10px;
  border: 5px solid var(--bs-sfc-blue);
  border-left: 10px solid var(--bs-sfc-blue);
}

.selected-course-card.blueberry {
  border-radius: 10px;
  border: 5px solid var(--bs-sfc-blueberry);
  border-left: 10px solid var(--bs-sfc-blueberry);
}

.selected-course-card .course-card {
  /* border-radius: 10px; */
  border: none !important;
}

.course-event-badge {
  background-color: #F2F2F2;
  border: 0.5px solid rgba(224, 224, 224, 0.5);
  font-size: 0.75rem;
  font-weight: 700;
  width: -webkit-fill-available;
  box-shadow: 0px -1px 4px 0px rgba(136, 136, 136, 0.2) inset;
  border-radius: 24px;
  text-align: center;
  max-width: 150px;
  color: white;
}

.course-event-badge.pink {
  background-color: var(--bs-sfc-pink);
}

.course-event-badge.orange {
  background-color: var(--bs-sfc-orange);
}

.course-event-badge.green {
  background-color: var(--bs-sfc-green);
}

.course-event-badge.blue {
  background-color: var(--bs-sfc-blue);
}

.course-event-badge.blueberry {
  background-color: var(--bs-sfc-blueberry);
}

.filter-badge.blueberry {
  background-color: var(--bs-sfc-blueberry);
  color: white;
}

.course-card-title {
  font-weight: 700;
  font-size: large;
}

.course-card-title.pink,
.course-card-distance.pink,
.course-card-link.pink {
  color: var(--bs-sfc-pink);
}

.course-card-title.orange,
.course-card-distance.orange,
.course-card-link.orange {
  color: var(--bs-sfc-orange);
}

.course-card-title.green,
.course-card-distance.green,
.course-card-link.green {
  color: var(--bs-sfc-green);
}

.course-card-title.blue,
.course-card-distance.blue,
.course-card-link.blue {
  color: var(--bs-sfc-blue);
}

.course-card-title.blueberry,
.course-card-distance.blueberry,
.course-card-link.blueberry {
  color: var(--bs-sfc-blueberry);
}