/* .scheduler-calendar .rbc-month-view {
  border: none;
}

.scheduler-calendar .rbc-day-bg + .rbc-day-bg {
  border: none;
} */

.scheduler-calendar .rbc-day-bg.rbc-today {
  background-color: transparent;
}