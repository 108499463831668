.swatch {
  width: 100%;
  text-align: center;
  margin-top: 5px;
  color: white;
  padding: 3px;
  border-radius: 15px;
}

.swatch.main, .swatch.pending {
  background-color: #000000;
}
.swatch.trainee, .swatch.enrolled, .swatch.draft {
  background-color: #7F9BB4;
}
.swatch.additional, .swatch.denied, .swatch.sent {
  background-color: #953E6A;
}
.swatch.sub, .swatch.substitute, .swatch.expired, .swatch.late {
  background-color: #CE4D2D;
}
.swatch.observer, .swatch.invited, .swatch.paid {
  background-color: #BFC662;
}