.availableJobs .bg-header{
  background-image: linear-gradient(rgba(161, 53, 108, .95), rgba(161, 53, 108, .95)), url("../../assets/images/footer-background.png");
    background-position: 0 0, 0 0;
  height: 160px;
}

.availableJobs .text-info {
  font-weight: 700;
}

.availableJobs .columns-video {
  flex-direction: column-reverse;
  display: flex;
}

.availableJobs .column-video-player {
  padding-left: 0;
}

.availableJobs .column-video-copy {
  text-align: left;
}

.availableJobs .section-video {
  margin-top: 5%;
  text-align: center;
  background-color: #f8faee;
  padding-bottom: 100px;
}

.availableJobs .instructorsText {
  width: 25%;
  text-align: left;
}

.availableJobs .instructorsVideo {
  width: 75%;
}

.availableJobs .instructorsSection {
  padding-top: 10%;
  flex-wrap: wrap;
}

@media (max-width: 1200px) {
  .availableJobs .instructorsText {
    width: 100%;
    text-align: center;
  }
  
  .availableJobs .instructorsVideo {
    width: 100%;
  }
}