.scale {
    display: flex;
    align-items: center;
}

.scale-copy {
    padding: 8px;
}

.scale-amount {
    color: #333;
    border: 1px solid #333;
    margin-right: 8px;
    padding: 8px;
    font-weight: 600;
}

.scale-amount.active {
    color: #bcc74f;
    border-color: #bcc74f;
}