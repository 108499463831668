.modal-header {
    border-bottom: none;
}

.logo-modal,
.sign-up-modal {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-modal {
    margin-bottom: 20px;
}

.login-modal {
    border-right: 1px dashed #d6d6d6;
}

.sign-up-content,
.login-modal-form {
    width: 60%;
    margin-right: auto;
    margin-left: auto;
}

.login-modal {
    text-align: center;
}

.sign-up-content {
    width: 80%;
    text-align: center;
}

.sign-up-btn {
    width: 60%;
    padding: 9px 20px;
    border-radius: 10px;
}

.modal-backdrop.show {
    opacity: 1;
    backdrop-filter: blur(2px);
    background-color: transparent;
}

.modal-content {
    border-radius: 0;
    border: 0;
    box-shadow: 0 0 10.68px 1.32px rgba(53, 48, 105, .08);
}