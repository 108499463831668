.custom-btn-group.btn-group .btn {
  background-color: #F2F2F2;
  border: 0.5px solid rgba(224, 224, 224, 0.5);
  color: #5E6469;
  font-size: 0.75rem;
  font-weight: 600;
  box-shadow: 0px -1px 4px 0px rgba(136, 136, 136, 0.2) inset;
}

.custom-btn-group.btn-group .btn:first-child {
  border-radius: 24px 0 0 24px;
}

.custom-btn-group.btn-group .btn:last-child {
  border-radius: 0 24px 24px 0;
}

.custom-btn-group.btn-group .btn.single {
  border-radius: 24px;
}

.custom-btn-group.btn-group .btn span {
  color: #888888;
  font-weight: 300;
}

.custom-btn-group .btn.active {
  background-color: rgba(94, 100, 105, 0.75);
  color: #FFFFFF;
}

.custom-btn-group .btn.active span {
  color: #FFFFFF;
}

.multi-custom-btn-group .btn {
  border-radius: 24px;
}