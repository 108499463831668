.stepper {
  justify-content: space-between;
  position: relative;
}

.circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ccc;
  color: rgba(94, 100, 105, 0.5);
  font-size: 1.25rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  transition: all ease-in 0.2s;
}

.circle.active {
  background-color: #B4C634;
  color: white;
}

.circle.completed {
  background-color: #FFFFFF;
  border: 2px solid #B4C634;
  color: #B4C634;
}

.label {
  color: rgba(94, 100, 105, 0.5);
  font-weight: 600;
  margin-top: 10px;
  white-space: nowrap;
  transition: all 0.4s;
}

.label.active {
  font-weight: 800;
}

.left-line {
  height: 1px;
  background-color: #E0E0E0;
  position: absolute;
  top: 20px;
  width: 15vh;
  right: 50%;
  z-index: 0;
  transition: 1s ease-out;
}

.right-line {
  height: 1px;
  background-color: #E0E0E0;
  position: absolute;
  top: 20px;
  width: 14vh;
  left: 50%;
  z-index: 0;
  transition: 1s ease-out;
}

.right-line.completed, .left-line.completed {
  background-color: #B4C634 !important;
  height: 2px;
}

.font-mobile{
  font-size: 0.6rem;
  
}