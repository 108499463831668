@media only screen and (min-width: 1024px) {
  .sfc-table {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, .1), 0 0 8px 0 rgba(0, 0, 0, .1);
    border-radius: 10px;
  }
}

.sfc-table.simple-table {
  box-shadow: none;
  border-radius: 0;
}

.sfc-table .table {
  border-radius: 10px;
}

.sfc-table th {
  background-color: #EBEBEB;
  font-size: .75rem;
  color: #888888;
  font-weight: 500;
  border-bottom: 1px solid #CDCDCD;
  padding: 12px;
  vertical-align: middle;
}

.sfc-table.simple-table th {
  padding: 12px;
}

.sfc-table td {
  font-size: .875rem;
  color: #5E6469;
}

.sfc-table tbody tr:hover {
  background: rgba(180, 198, 52, .1);
}

.sfc-table .form-select.pagination {
  background: #FFFFFF url(../../assets/icons/up-down-caret.svg) no-repeat 22px center;
  border-radius: 2px;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}

.sfc-table.simple-table thead {
  position: sticky;
  top: 0;
  background-color: #EBEBEB;
  z-index: 1;

}

.sfc-table.simple-table tbody {
  overflow-y: scroll;
  height: calc(100% - 40px); /* adjust the height to subtract the header height */
}