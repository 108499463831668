
// Custom.scss
// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here
$primary: #B4C634;
$secondary: #5E6469;
$info: #2B4E64;
$danger: #ff4136;
$dark: #333333;

$gray-darker: rgba(94, 100, 105, .5); 

$min-contrast-ratio: 1.5;

$accordion-bg: transparent;
$accordion-color: $secondary;
$accordion-button-color: $secondary;
$accordion-button-active-color: $secondary;
$accordion-button-icon: url("data:image/svg+xml, <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M6.96967 2.46967C7.26256 2.17678 7.73744 2.17678 8.03033 2.46967L17.0303 11.4697C17.3232 11.7626 17.3232 12.2374 17.0303 12.5303L8.03033 21.5303C7.73744 21.8232 7.26256 21.8232 6.96967 21.5303C6.67678 21.2374 6.67678 20.7626 6.96967 20.4697L15.4393 12L6.96967 3.53033C6.67678 3.23744 6.67678 2.76256 6.96967 2.46967Z' fill='#5E6469' stroke='#5E6469' stroke-linecap='round' stroke-linejoin='round'/></svg>");
$accordion-button-active-icon: url("data:image/svg+xml, <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M6.96967 2.46967C7.26256 2.17678 7.73744 2.17678 8.03033 2.46967L17.0303 11.4697C17.3232 11.7626 17.3232 12.2374 17.0303 12.5303L8.03033 21.5303C7.73744 21.8232 7.26256 21.8232 6.96967 21.5303C6.67678 21.2374 6.67678 20.7626 6.96967 20.4697L15.4393 12L6.96967 3.53033C6.67678 3.23744 6.67678 2.76256 6.96967 2.46967Z' fill='#5E6469' stroke='#5E6469' stroke-linecap='round' stroke-linejoin='round'/></svg>");
$accordion-icon-color: $secondary;
$accordion-icon-active-color: $secondary;
$accordion-icon-transform: rotate(90deg);

$badge-font-size: .75rem;
$badge-font-weight: 600;
$badge-color: #888888;

$btn-border-width: 2px;
$btn-font-weight: 800;

$input-color: #888888;
$input-padding-y: 10px;
$input-border-color: $gray-darker;
$input-border-radius: 8px;
$input-placeholder-color: rgba(94, 100, 105, .5);

$input-group-addon-bg: transparent;

$font-weight-bolder: 800;

$form-check-label-color: $gray-darker;

$form-check-input-border: 1px solid $gray-darker;
$form-check-input-border-radius: 2px;

$form-label-margin-bottom: 0;
$form-label-color: $gray-darker;
$form-label-font-weight: 600;

$form-select-indicator: url("data:image/svg+xml,<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M4.64645 1.64645C4.84171 1.45118 5.15829 1.45118 5.35355 1.64645L11.3536 7.64645C11.5488 7.84171 11.5488 8.15829 11.3536 8.35355L5.35355 14.3536C5.15829 14.5488 4.84171 14.5488 4.64645 14.3536C4.45118 14.1583 4.45118 13.8417 4.64645 13.6464L10.2929 8L4.64645 2.35355C4.45118 2.15829 4.45118 1.84171 4.64645 1.64645Z' fill='#333333'/></svg>");

$navbar-light-color: #2B4E64;
$navbar-light-active-color: #FFFFFF;

$nav-link-padding-y: 1rem;
$navbar-nav-link-padding-x: 1rem;
$nav-pills-border-radius: 0;

$dropdown-border-width: 0;
$dropdown-padding-y: 10px;
$dropdown-border-radius: 8px 0 8px 8px;
$dropdown-link-color: #2B4E64;
$dropdown-link-hover-color: #FFFFFF;
$dropdown-link-hover-bg: #2B4E64;
$dropdown-link-active-bg: $primary;
$dropdown-item-padding-y: 5px;

$popover-border-width: 0px;
$popover-border-radius: 8px;
$popover-max-width: 326px;

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/variables-dark";

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";

// Create your own map
$custom-colors: (
  "sfc-orange": #d36a48,
  "sfc-pink": #BA1F69,
  "sfc-blue": #37576c,
  "sfc-green": #B4C634,
  "sfc-gray": #5E6469,
  "sfc-blueberry": #6098B8,
);

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);

// 6. Optionally include any other parts as needed
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/helpers";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "../node_modules/bootstrap/scss/utilities/api";

// 8. Add additional custom code here
@import "../node_modules/bootstrap/scss/bootstrap.scss";
