.navbar-emptyspace {
  width: 33.3%;
}

.navbar-buttons{
  min-width: 370px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.navbar-container{
  display: flex;
  justify-content: end;
  width: 33.3%;
  align-self: center;
  margin-top: 4%;
}

.header-container{
  display: flex;
  width: 100%;
}

.logo-container{
  display:flex;
  justify-content: center;
  width: 33.3%;
}

@media (max-width: 1200px) {
  .header-container{
    flex-wrap: wrap;
    justify-content: center;
  }

  .navbar-emptyspace{
    width: 0%;
  }

  .logo-container{
    width: 80%;
  }

  .navbar-container{
    width: 100%;
    justify-content: flex-start;
    margin-top: 0%;
    margin-bottom: 5%;
  }

  .nav-link{
    padding-left: 4%;
  }
}