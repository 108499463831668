.no-class-container {
    height: 81%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.no-class-label {
    font-weight: 600;
    color: #5E6469BF;
    font-style: italic;
    font-weight: 18px;
}

.lesson-detail {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;
    margin-top: 3px;
    margin-bottom: 3px;
}

.lesson-detail .detail-icon {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.lesson-detail .detail-text {
    padding: 0 3px;
    flex: 1;
    align-self: flex-start;
}

.children-enrolled {
    margin-top: 8px;
    font-weight: 600;
    font-size: 18px;
}

.children-enrolled .form-check-label {
    color: #5E6469;
}