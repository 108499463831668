.recipe-child-joke {
    background-image: linear-gradient(rgba(255, 255, 255, .6), rgba(255, 255, 255, .6)), url('../../../assets/images/226820_StickyFingers_FruitToTile2_Cornucopia_650.png');
    background-position: 0 0, 50%;
    background-repeat: repeat, repeat;
    background-size: auto, auto;
    background-attachment: scroll, scroll;
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 20px;
}

.recipe-child-joke .joke-main {
    text-align: center;
    background-color: #fff;
    padding: 20px;
}

.recipe-child-joke .image-joke {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
}

.recipe-child-joke .divider-35 {
    width: 35%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0);
    border-top: 1px dashed #d6d6d6;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
}

.recipe-child-joke .joke {
    padding-left: 20px;
    padding-right: 20px;
}