table {
    width: 100%;
    /* border-collapse: collapse; */
  }
  
  th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  th {
    background-color: #f0f0f0;
  }
  
  tr:hover {
    background-color: #f9f9f9;
  }
  